var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { CommunicationDataService } from "~/services/repair-service/communication-data.service";
import { DataRepairImportService } from "~/services/repair-service/data-repair-import.service";
import AddCommunicateData from "~/components/data-repair/communicate-data/add-communicate-data.vue";
import EditCommunicateData from "~/components/data-repair/communicate-data/edit-communicate-data.vue";
import CommunicateDetail from "~/components/data-repair/communicate-data/communicate-detail.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import RepairDataImport from "~/components/data-repair/repair-data-import.vue";
import ImportBatchDeletion from "~/components/data-repair/census-data/import-batch-deletion.vue";
import { dataRepair } from "~/config/enum.config";
//@Auth(105)
var CommunicateData = /** @class */ (function (_super) {
    __extends(CommunicateData, _super);
    function CommunicateData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.rowData = {};
        _this.importType = "PHONE_RESOURCE"; //通讯资料
        _this.censusModel = {};
        _this.editModel = {};
        _this.dataSet = [];
        _this.typeSet = [];
        _this.currentType = dataRepair;
        _this.searchParamsModel = {
            name: "",
            idNo: "",
            type: "",
            applyTransferArea: "",
            importType: "",
            applyFileContent: ""
        };
        _this.data = {};
        _this.dialog = {
            detail: false,
            edit: false,
            import: false,
            deletion: false
        };
        return _this;
    }
    CommunicateData.prototype.created = function () { };
    CommunicateData.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 查看通讯资料详情
     */
    CommunicateData.prototype.viewDetails = function (row) {
        this.rowData = row;
        this.dialog.communicate = true;
    };
    /**
     * keep-alive生命周期钩子函数
     */
    CommunicateData.prototype.activated = function () {
        // 加载数据
        this.refreshData();
    };
    /**
     * 查询通讯资料
     */
    CommunicateData.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.communicationDataService
            .search(this.searchParamsModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
        this.getDataType();
    };
    /**
     * 查询类型
     */
    CommunicateData.prototype.getDataType = function () {
        var _this = this;
        this.loading.state = true;
        this.searchParamsModel.importType = this.importType;
        this.dataRepairImportService
            .getDataTypes(this.searchParamsModel, this.loading)
            .subscribe(function (data) {
            _this.typeSet = data;
        });
        this.searchParamsModel.importType = null;
    };
    /**
     * 添加户籍弹窗
     */
    CommunicateData.prototype.addClick = function () {
        this.dialog.detail = true;
    };
    CommunicateData.prototype.dataImpClick = function () {
        this.dialog.import = true;
    };
    /**
     * 获取下载模板
     */
    CommunicateData.prototype.downLoadTemplate = function () {
        var _this = this;
        this.searchParamsModel.applyFileContent = this.importType;
        this.dataRepairImportService
            .getSystemTemplate(this.searchParamsModel, this.loading)
            .subscribe(function (data) {
            if (data) {
                _this.uploadFileService
                    .getFileStreamById(data.value)
                    .subscribe(function () { return _this.$message.success("下载成功"); });
            }
        }, function (_a) {
            var msg = _a.msg;
        });
        this.searchParamsModel.applyFileContent = null;
    };
    /**
     * 删除通讯资料
     */
    CommunicateData.prototype.deleteKoseki = function (scope) {
        var _this = this;
        this.$confirm("您确定要删除吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.loading.state = true;
            _this.communicationDataService
                .delete(scope.row, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.refreshData();
            });
        }, function (err) {
            _this.$message.error(err.msg);
        })
            .catch(function () {
            _this.$message({
                type: "info",
                message: "已取消删除"
            });
        });
    };
    /**
     * 修改户籍弹框
     */
    CommunicateData.prototype.editKoseki = function (scope) {
        this.editModel = scope.row;
        this.dialog.edit = true;
    };
    CommunicateData.prototype.importBatchDeletion = function () {
        this.dialog.deletion = true;
    };
    __decorate([
        Dependencies(PageService)
    ], CommunicateData.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CommunicateData.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CommunicationDataService)
    ], CommunicateData.prototype, "communicationDataService", void 0);
    __decorate([
        Dependencies(DataRepairImportService)
    ], CommunicateData.prototype, "dataRepairImportService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], CommunicateData.prototype, "uploadFileService", void 0);
    CommunicateData = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                AddCommunicateData: AddCommunicateData,
                EditCommunicateData: EditCommunicateData,
                RepairDataImport: RepairDataImport,
                CommunicateDetail: CommunicateDetail,
                ImportBatchDeletion: ImportBatchDeletion
            }
        })
    ], CommunicateData);
    return CommunicateData;
}(Vue));
export default CommunicateData;
